/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBatchExecutions = /* GraphQL */ `
  query GetBatchExecutions($jobDefinitions: [String]) {
    getBatchExecutions(jobDefinitions: $jobDefinitions) {
      jobId
      jobName
      jobArn
      status
      statusReason
      createdAt
      startedAt
      stoppedAt
    }
  }
`;
export const getJobQueues = /* GraphQL */ `
  query GetJobQueues {
    getJobQueues {
      jobQueueName
    }
  }
`;
export const getJobDefinitions = /* GraphQL */ `
  query GetJobDefinitions($status: String) {
    getJobDefinitions(status: $status) {
      jobDefinitionName
      jobDefinitionArn
      revision
      type
      status
    }
  }
`;
export const getScheduledRules = /* GraphQL */ `
  query GetScheduledRules {
    getScheduledRules {
      Name
      Arn
      EventPattern
      State
      Description
      ScheduleExpression
      ManagedBy
      EventBusName
    }
  }
`;
export const getExecutionReport = /* GraphQL */ `
  query GetExecutionReport($reportName: String!) {
    getExecutionReport(reportName: $reportName)
  }
`;
export const hasExecutionReport = /* GraphQL */ `
  query HasExecutionReport($reportName: String!) {
    hasExecutionReport(reportName: $reportName)
  }
`;
export const getLogEvents = /* GraphQL */ `
  query GetLogEvents($jobId: String!) {
    getLogEvents(jobId: $jobId) {
      nextForwardToken
      events {
        timestamp
        message
      }
    }
  }
`;
export const getJobDescription = /* GraphQL */ `
  query GetJobDescription($jobId: String) {
    getJobDescription(jobId: $jobId) {
      jobId
      status
    }
  }
`;
