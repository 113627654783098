/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import * as React from 'react';
import {
  Box,
  Grid,
  Button, Paper,
  TableCell, TableRow, TableContainer, TableBody, TableHead, Table, LinearProgress, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import GraphqlService from '../service/graphqlService';
import PrimaryButton from '../components/utils/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  containerButtons: {
    marginBottom: 16,
  },
  table: {
    minWidth: 200,
    maxWidth: 976,
    textAlign: 'justify',
  },
  tableCell: {
    verticalAlign: 'top',
  },
}));

export default function ExecutionLogs() {
  const [executionLogs, setExecutionLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationToken, setPaginationToken] = useState('');

  const classes = useStyles();
  const { jobId, jobName } = useParams();
  const history = useHistory();

  // DATA RETRIEVAL_______________________
  async function fetchExecutionLogs() {
    setLoading(true);
    try {
      const getLogsOutput = await GraphqlService.getExecutionLogs(jobId, paginationToken);
      console.log(getLogsOutput);
      setExecutionLogs((prev) => [...prev, ...getLogsOutput?.events]);
      setPaginationToken(getLogsOutput?.nextForwardToken);
    } catch (ex) {
      console.error(ex);
      setPaginationToken('');
    }
    console.log(executionLogs);
    console.log(paginationToken);
    setLoading(false);
  }

  // WEBPAGE______________________________

  const downloadDoc = () => {
    setLoading(true);
    const element = document.createElement('a');
    const file = new Blob(
      [executionLogs.map((log) => (`${moment(log.timestamp).format('DD/MM/yyyy HH:mm:ss,SSS')}|${log.message}\n`))],
      { type: 'text/plain' },
    );
    element.href = URL.createObjectURL(file);
    element.download = `${jobName}.log`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  useEffect(() => {
    fetchExecutionLogs();
  }, []);

  useEffect(() => {
    if (paginationToken) fetchExecutionLogs();
  }, [paginationToken]);

  return (
    <Box>
      {loading && <LinearProgress />}
      <Grid
        item
        container
        xs={12}
        spacing={0}
        direction="column"
        className={classes.content}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.containerButtons}
        >
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={history.goBack}
              className={classes.button}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <PrimaryButton
              type="button"
              variant="contained"
              color="primary"
              onClick={downloadDoc}
              className={classes.button}
              style={{ margin: 0 }}
            >
              Download
            </PrimaryButton>
          </Grid>
        </Grid>
        <Grid item xs className={classes.containerTable}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="logs table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {`Execution logs for ${jobName} (${jobId})`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={jobId}>
                  <TableCell className={classes.tableCell}>
                    {executionLogs?.map((log) => (
                      <Typography variant="p" style={{ wordBreak: 'break-all' }} align="justify" display="block">
                        {`${moment(log.timestamp).format('DD/MM/yyyy HH:mm:ss,SSS')}  |  ${log.message}'`}
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

    </Box>

  );
}
