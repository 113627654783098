/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Snackbar } from '@material-ui/core';
import Header from './Header';
import LeftBar from './LeftBar';
import routes from '../config/routes';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: 1324,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100vh',
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.background,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.appBar - 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
}));

function Routes(openSnackBar, model) {
  return routes.map(({ path: routespath, component: Component }) => (
    <Route
      exact
      path={routespath}
      key={routespath}
      render={(props) => {
        const crumbs = routes
        // Get all routes that contain the current one.
          .filter(({ path: filterpath }) => props?.match.path.includes(filterpath))
        // Swap out any dynamic routes with their param values.
          .map(({ path: mappath, name: mapname, ...rest }) => ({
            name: Object.keys(props?.match.params).length
              ? Object.keys(props?.match.params).map(
                (param) => (
                  mappath.endsWith(`:${param}`)
                    ? props?.match?.params[param]
                    : mapname),
              )[0]
              : mapname,
            path: Object.keys(props?.match.params).length
              ? Object.keys(props?.match.params).reduce(
                (path, param) => path.replace(
                  `:${param}`, props?.match.params[param],
                ), mappath,
              )
              : mappath,
            ...rest,
          }))
          .reverse();
        return (
          <div className="p-8">
            <Component openSnackBar={openSnackBar} />
          </div>
        );
      }}
    />
  ));
}

export default function Layout() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');

  const handleDrawer = () => {
    setOpen(!open);
  };

  function openSnackBar(text) {
    setSnackBarText(text);
    setSnackBarOpen(true);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <LeftBar open={open} setOpen={setOpen} />
        <div className={classes.mainContainer}>
          <Header handleDrawer={handleDrawer} />
          <Switch>
            {Routes(openSnackBar)}
            <Redirect to="/batchExecutions" />
          </Switch>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBarOpen}
          autoHideDuration={3000}
          message={snackBarText}
          action={(
            <Button color="inherit" size="small" onClick={() => setSnackBarOpen(false)}>
              Close
            </Button>
              )}
          onClose={() => setSnackBarOpen(false)}
          key="snackbar"
        />
      </main>
    </div>
  );
}
