/* eslint-disable import/prefer-default-export */
import {
  CUCUMBER_NAME, SCHEDULED_API, SCHEDULED_USER_CREATION, USER_CREATION_NAME,
  JOB_NAME_CREATION, JOB_NAME_USER,
} from './constants';

function calculateJobName(jobName) {
  if (jobName === SCHEDULED_USER_CREATION || (jobName.toLowerCase().includes(JOB_NAME_CREATION)
  && jobName.toLowerCase().includes(JOB_NAME_USER))) {
    return SCHEDULED_USER_CREATION;
  }
  return jobName;
}

export function getReportName(jobId, jobName) {
  let name = jobId + CUCUMBER_NAME;
  let formatted = false;
  switch (calculateJobName(jobName)) {
    case SCHEDULED_USER_CREATION: {
      name = jobId + USER_CREATION_NAME;
      formatted = true;
      break;
    }
    case SCHEDULED_API: {
      name = jobId + CUCUMBER_NAME;
      break;
    }
    default: {
      name = jobId + CUCUMBER_NAME;
      break;
    }
  }
  return { name, formatted };
}
