/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import * as React from 'react';
import {
  Box,
  Grid,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import GraphqlService from '../service/graphqlService';
import PrimaryButton from '../components/utils/PrimaryButton';
import {
  CUCUMBER_NAME, USER_CREATION_NAME, API_NAME, SCHEDULED_USER_CREATION, SCHEDULED_API,
} from '../utils/constants';
import { getReportName } from '../utils/mappers';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  containerButtons: {
    marginBottom: 16,
  },
  button: {
  },
  iframeLayout: {
    borderRadius: 5,
    width: '100%',
  },
}));

export default function ExecutionReport() {
  const [executionReport, setExecutionReport] = useState();
  const [formatted, setFormatted] = useState();
  const [reportName, setReportName] = useState('');
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const { jobId, jobName } = useParams();
  const history = useHistory();

  // DATA RETRIEVAL_______________________
  async function fetchExecutionReport() {
    setLoading(true);
    const { name, formatted: formatd } = getReportName(jobId, jobName);
    setFormatted(formatd);
    setReportName(name);
    const fetchedExecutionReport = await GraphqlService.getExecutionReport(name);
    setExecutionReport(fetchedExecutionReport);
    setLoading(false);
  }

  function setIframe(content) {
    const iframe = document.getElementById('ifr')?.contentWindow?.document;
    iframe?.open();
    iframe?.write(content);
    iframe?.close();
    document.getElementById('ifr').height = iframe?.body.scrollHeight;
  }

  // WEBPAGE______________________________

  const downloadDoc = () => {
    const element = document.createElement('a');
    const file = new Blob([executionReport], { type: `text/${reportName.substring(reportName.lastIndexOf('.') + 1)}` });
    element.href = URL.createObjectURL(file);
    element.download = reportName.substring(reportName.lastIndexOf('/') + 1);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  useEffect(() => {
    const fetch = setTimeout(10, fetchExecutionReport());
    return clearTimeout(fetch);
  }, []);

  useEffect(() => {
    if (executionReport && !formatted) setIframe(executionReport);
  }, [executionReport]);

  return (
    <Box>
      {loading && <LinearProgress />}
      <Grid
        item
        container
        xs={12}
        spacing={0}
        direction="column"
        className={classes.content}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.containerButtons}
        >
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={history.goBack}
              className={classes.button}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <PrimaryButton
              type="button"
              variant="contained"
              color="primary"
              onClick={downloadDoc}
              className={classes.button}
              style={{ margin: 0 }}
            >
              Download
            </PrimaryButton>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={0}
          direction="row"
          className={classes.bottomContent}
        >
          {formatted
            ? (
              <Typography style={{ wordBreak: 'break-all', fontFamily: 'monospace' }}>
                {executionReport?.split('\n').map((i, key) => <p key={key}>{i}</p>)}
              </Typography>
            )
            : (
              <iframe
                key="ifr"
                id="ifr"
                title="iframe title"
                frameBorder="0"
                className={classes.iframeLayout}
              />
            )}
        </Grid>
      </Grid>

    </Box>

  );
}
