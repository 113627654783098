/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { API, Auth } from 'aws-amplify';
import {
  getBatchExecutions,
  getJobQueues,
  getJobDefinitions,
  getExecutionReport,
  getLogEvents,
  hasExecutionReport,
  getJobDescription,
} from '../graphql/queries';

import {
  submitJob,
} from '../graphql/mutations';

export default class GraphqlService {
  static async getBatchExecutions(jobDefinitions) {
    console.log('In getBatchExecutions....');

    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: getBatchExecutions,
      variables: {
        jobDefinitions,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    console.log(`getBatchExecutions result....${JSON.stringify(postData)}`);

    return postData.data.getBatchExecutions;
  }

  static async getJobQueues() {
    console.log('In getJobQueues....');
    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: getJobQueues,
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`getJobQueues result....${JSON.stringify(postData)}`);

    return postData.data.getJobQueues;
  }

  static async getJobDefinitions() {
    console.log('In getJobDefinitions....');
    const user = await Auth.currentAuthenticatedUser();
    const status = 'ACTIVE';

    const postData = await API.graphql({
      query: getJobDefinitions,
      variables: {
        status,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`getJobDefinitions result....${JSON.stringify(postData)}`);

    return postData.data.getJobDefinitions;
  }

  static async getExecutionReport(reportName) {
    console.log('In getExecutionReport....');
    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: getExecutionReport,
      variables: {
        reportName,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`getExecutionReport result....${JSON.stringify(postData)}`);

    return postData.data.getExecutionReport;
  }

  static async hasExecutionReport(reportName) {
    console.log('In hasExecutionReport....');
    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: hasExecutionReport,
      variables: {
        reportName,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`hasExecutionReport result....${JSON.stringify(postData)}`);

    return postData.data.hasExecutionReport;
  }

  static async getExecutionLogs(jobId, nextToken) {
    console.log('In getExecutionLogs....');
    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: getLogEvents,
      variables: {
        jobId,
        nextToken,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`getExecutionLogs result....${JSON.stringify(postData)}`);

    return postData.data.getLogEvents;
  }

  static async getJobDescription(jobId) {
    console.log('In getJobDescription....');
    const user = await Auth.currentAuthenticatedUser();

    const postData = await API.graphql({
      query: getJobDescription,
      variables: {
        jobId,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });

    console.log(`getJobDescription result....${JSON.stringify(postData)}`);

    return postData.data;
  }

  static async submitJob(jobName, jobDefinition) {
    console.log('In submitJob....');
    try {
      const user = await Auth.currentAuthenticatedUser();
      const submitJobInput = {
        jobName,
        jobDefinition,
      };

      const postData = await API.graphql({
        query: submitJob,
        variables: {
          submitJobInput,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        // Some error happen
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.submitJob.__typename === 'SubmitJobSuccess') {
        // All went OK
        console.log(`submitJob result....${JSON.stringify(postData)}`);
        return postData.data.submitJob.jobId;
      }
      return Promise.reject(new Error(postData.data.submitJob.message)); // Validation error
    } catch (data) {
      console.log('Error: ', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }
}
