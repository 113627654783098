import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PrimaryButton from './utils/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 13,
    backgroundColor: theme.palette.common.black,
  },
  avatarText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 8,
    marginRight: 8,
    fontSize: 16,
  },
  avatarArrow: {
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 24,
  },
}));

function CustomSignoutButton() {
  const signOut = async (e) => {
    e.preventDefault();
    await Auth.signOut();
    window.location.reload();
  };

  return (
    <PrimaryButton
      type="button"
      onClick={signOut}
      style={{
        width: '100%',
        borderRadius: 0,
        margin: 0,
      }}
    >
      Sign out
    </PrimaryButton>
  );
}

function Profile() {
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  async function checkUser() {
    const authUser = await Auth.currentAuthenticatedUser();
    setUser(authUser);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  useEffect(() => {
    checkUser();
  }, []);

  if (!user) return null;
  return (
    <div>
      <Grid
        container
        onClick={handleClick}
      >
        <Grid item>
          <Avatar
            aria-describedby={id}
            alt={user.username}
            className={classes.avatar}
          >
            {user.username.substring(0, 1).toUpperCase()}
          </Avatar>

        </Grid>
        <Grid item className={classes.avatarText}>
          {user.username}
        </Grid>
        <Grid item className={classes.avatarArrow}>
          <ArrowDropDownIcon />
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{user.username}</Typography>
        <CustomSignoutButton />
      </Popover>
    </div>
  );
}
export default Profile;
