/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles, Popper } from '@material-ui/core';
import { React } from 'react';

export default function CustomPopper(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: theme.zIndex.modal,
      '& .MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: theme.palette.background.background,
        color: theme.palette.primary.main,
        fontSize: '1rem',
      },
      '& li': {
        padding: '1em',
        fontWeight: 500,
      },
    },
  }));

  const classes = useStyles();
  return <Popper {...props} className={classes.root} placement="bottom" />;
}
