/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PrimaryButton from './PrimaryButton';

const useStyles = makeStyles((theme) => ({
  customAlert: {
    padding: 20,
  },
}));

export default function SimpleAlert({
  open, setOpen, header, body, callback,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (callback) callback();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.customAlert}>{header || 'Alert'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.customAlert}>
        <PrimaryButton onClick={handleClose} color="primary" autoFocus>
          Close
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
SimpleAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  callback: PropTypes.func,
};
SimpleAlert.defaultProps = {
  callback: () => {},
};
