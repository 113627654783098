/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitJob = /* GraphQL */ `
  mutation SubmitJob($submitJobInput: SubmitJobInput!) {
    submitJob(submitJobInput: $submitJobInput) {
      ... on SubmitJobSuccess {
        __typename
        jobId
        jobName
      }
      ... on BadRequest {
        message
        statusCode
      }
      ... on InternalServerError {
        message
        statusCode
      }
      ... on NotFound {
        message
        statusCode
      }
      ... on Forbidden {
        message
        statusCode
      }
    }
  }
`;
export const cancelJob = /* GraphQL */ `
  mutation CancelJob($jobId: String!, $reason: String!) {
    cancelJob(jobId: $jobId, reason: $reason) {
      result
    }
  }
`;
