import BatchExecutions from '../screens/BatchExecutions';
import ExecutionLogs from '../screens/ExecutionLogs';
import ExecutionReport from '../screens/ExecutionReport';

export default [ // Must go from specific to general
  {
    path: '/batchExecutions', name: 'Batch Executions', component: BatchExecutions,
  },
  {
    path: '/batchExecutions/getReport/:jobName/:jobId', name: 'Get Execution Report', component: ExecutionReport,
  },
  {
    path: '/batchExecutions/listLogs/:jobName/:jobId', name: 'Get Execution Logs', component: ExecutionLogs,
  },
];
