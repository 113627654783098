export const UserPoolClientId = '3kpciu7nnav6atq1hs7b7bib16';
export const AppSyncAPIKey = 'da2-fznbvjmtrvgfxf25z2oo5h27bq';
export const UserPoolId = 'eu-west-1_K49R5Uwt6';
export const GraphQLAPIURL = 'https://zey4lknybzd2tp4wlitaucpwze.appsync-api.eu-west-1.amazonaws.com/graphql';
export const IdentityPoolId = 'eu-west-1:5279a90e-5c14-44ae-954c-31716a8ac672';
export const target = 'DEV';
export const region = 'eu-west-1';

const config = {
  aws_project_region: region,
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_apiKey: AppSyncAPIKey,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    identityPoolId: IdentityPoolId,
    region,
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
};

export default config;
