/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React,
} from 'react';
import {
  AppBar, Hidden, IconButton,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import Profile from './Profile';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
  },
  toolbar: {
    boxShadow: theme.customShadows.wholeShadow,
  },
  grow: {
    flexGrow: 1,
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.common.black,
  },
  // this group of buttons will be aligned to the right side
  toolbarOptions: {
    marginLeft: 'auto',
    marginRight: '1em',
    direction: 'rtl',
  },
}));

function Header({ handleDrawer }) {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.grow} />
        <Profile />
      </Toolbar>
    </AppBar>
  );
}
Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
};

export default Header;
