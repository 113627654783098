/* eslint-disable max-len */
import './App.css';
import React from 'react';
import { HashRouter } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Layout from './components/Layout';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#8F7247',
      button: '#000000',
      bannerText: '#ffffff',
      text: '#000000',
    },
    secondary: {
      main: '#c6b69f',
      contrastText: '#000000',
      text: '#666666',
    },
    error: {
      main: '#DD3232',
      statistics: '#D50000',
    },
    warning: {
      main: '#FAAF19',
      statistics: '#FA6119',
    },
    success: {
      main: '#3D8705',
      statistics: '#3F9C35',
    },
    background: {
      paper: '#FFFFFF',
      default: '#D8CEBF',
      tableHeader: '#EBEBEB',
      separator: '#A1A1A1',
      background: '#F6F4F2',
      dropzone: '#979797',
      black: '#000000',
    },
    colors: {
      statistics: {
        color1: '#7986cb',
        color2: '#ffb74d',
        color3: '#ba68c8',
        color4: '#81c784',
        color5: '#467eac',
        color6: '#ffd54f',
        color7: '#a84466',
        color8: '#aed581',
        color9: '#f06292',
        color10: '#00cc00',
        color11: '#800000',
        color12: '#ff6c17',
      },
    },
  },
  typography: {
    fontFamily: ['Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.3rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    h7: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    releaseTableHeader: {
      lineHeight: 0,
      fontSize: 12,
    },
    releaseTableText: {
      fontSize: 14,
    },
  },
  customShadows: {
    wholeShadow: '0px 4px 4px 0px rgba(0,0,0,0.24)',
    rightShadow: '4px -4px 8px 0px rgba(0,0,0,0.24)',
    wholeShadowAllSides: '0px 0px 4px 0px rgba(0,0,0,0.24)',
    noTopShadow: '0px 4px 4px 0px rgba(0,0,0,0.24)',
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected, $selected:hover': {
          color: '#8F7247',
          backgroundColor: '#F6F4F2',
          '& g': {
            fill: '#8F7247',
          },
          '& .MuiTypography-displayBlock': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: '',
      },
    },
    MuiFilledInput: {
      input: {
        '&::placeholder': {
          color: '#666666',
          opacity: 1,
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Layout />
      </HashRouter>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
