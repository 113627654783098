/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import * as React from 'react';
import {
  Box, Grid, Table,
  TableBody, TableContainer,
  TableRow, Paper, TableCell, TableHead, TextField, Button, TableFooter, TablePagination,
  IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import ViewIcon from '@material-ui/icons/Visibility';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GraphqlService from '../service/graphqlService';
import TablePaginationActions from '../components/utils/TablePaginationActions';
import CustomPopper from '../components/utils/CustomPopper';
import SimpleAlert from '../components/utils/SimpleAlert';
import { ReactComponent as SubmitIcon } from '../assets/ic_release.svg';

import { STATUSES_WITH_LOGS, STATUSES_ON_GOING } from '../utils/constants';
import { getReportName } from '../utils/mappers';

const useStyles = makeStyles((theme) => ({
  headerTable: {
    font: theme.typography.h5.font,
    fontSize: 12,
    color: theme.palette.common.black,
    paddingBottom: 16,
  },
  headerCell: {
    paddingLeft: 24,
  },
  headerBorderTable: {
    borderBottom: `1.5px solid ${theme.palette.primary.main}`,
  },
  topField: {
    minHeight: 76,
  },
  bottomRightButton: {
    paddingRight: 8,
    paddingLeft: 8,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    paddingTop: 8,
    width: 138,
  },
  bottomLeftButton: {
    paddingRight: 8,
    paddingLeft: 8,
    alignSelf: 'flex-end',
    borderRadius: 0,
    paddingTop: 8,
    width: 138,
  },
  historyTable: {
    borderRadius: 0,
    minWidth: 200,
    maxWidth: 976,
    '& tr': {
      maxWidth: 976,
      height: 48,
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
  },
  bottomContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.button,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    marginBottom: 24,
    borderRadius: 2,
    '&:disabled': {
      color: theme.palette.primary.light,
    },
  },
  actionIcons: {
    scale: 1.5,
    '&:hover': {
      color: 'rgb(138, 127, 111)',
    },
  },
  gridSubmit: {
    alignSelf: 'flex-start',
    paddingLeft: 24,
  },
  text: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
  },
}));

export default function BatchExecutions() {
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const [batchExecutionsPerPage, setBatchExecutionsPerPage] = useState([]);
  const [batchExecutions, setBatchExecutions] = useState([]);
  const [selectedJobDefinition, setSelectedJobDefinition] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [jobDefinitions, setJobDefinitions] = useState([]);
  const [submitJobDisabled, setSubmitJobDisabled] = useState(true);
  const [searched, setSearched] = useState(false);
  const [cancelPolling, setCancelPolling] = useState(null);

  const POLLING_INTERVAL = 60000;
  const history = useHistory();
  const classes = useStyles();

  const {
    handleSubmit,
  } = useForm();

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }
  // DATA RETRIEVAL_______________________

  async function isJobExecuting(listResults) {
    const sortedList = listResults.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    if (sortedList.length > 0) {
      return STATUSES_ON_GOING.includes(sortedList[0].status);
    }
    return false;
  }

  async function fetchBatchExecutions(jobDefinition, pageNumber) {
    // eslint-disable-next-line max-len
    const batchExecutionsResult = await GraphqlService.getBatchExecutions(jobDefinition.jobDefinitionArn);
    const haveReports = await Promise.all(
      batchExecutionsResult.map((result) => {
        if (result.status === 'SUCCEEDED') {
          return GraphqlService
            .hasExecutionReport(getReportName(result.jobId, result.jobName)?.name);
        }
        return new Promise((resolve) => resolve(false));
      }),
    );
    // eslint-disable-next-line no-return-assign
    batchExecutionsResult.forEach((res, index) => res.hasReport = haveReports[index]);

    // eslint-disable-next-line no-param-reassign
    // eslint-disable-next-line no-console
    setBatchExecutions(batchExecutionsResult);
    setTotalCount(batchExecutionsResult.length);

    const resultPage = batchExecutionsResult.slice(pageNumber * rowsPerPage,
      (pageNumber + 1) * rowsPerPage);

    setBatchExecutionsPerPage(resultPage);

    // if there is something in execution ....
    // POLLING
    const isWorking = await isJobExecuting(batchExecutionsResult);

    if (isWorking) {
      // eslint-disable-next-line max-len
      const myPolling = setTimeout(async () => {
        await fetchBatchExecutions(jobDefinition, pageNumber); // setSelectedJobDefinition
      }, POLLING_INTERVAL);
      setCancelPolling(myPolling);
    }
    setSubmitJobDisabled(isWorking);
    setSearched(true);

    return batchExecutionsResult;
  }

  async function fetchJobDefinitions() {
    const fetchedJobDefinitions = await GraphqlService.getJobDefinitions();
    setJobDefinitions(fetchedJobDefinitions);
    // eslint-disable-next-line max-len
    // if arn present in the URL use it to reload the page because we are returning with the back button
    const queryParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));
    const term = queryParams.get('q');
    const selectedItem = fetchedJobDefinitions.find((item) => item.jobDefinitionArn === term);
    if (selectedItem) {
      setSelectedJobDefinition(selectedItem);
      fetchBatchExecutions(selectedItem, 0);
    }
  }

  async function submitJobOnDemand() {
    try {
      // eslint-disable-next-line max-len
      await GraphqlService.submitJob(selectedJobDefinition.jobDefinitionName, selectedJobDefinition.jobDefinitionArn);
      const listResults = await fetchBatchExecutions(selectedJobDefinition, 0);
      setSubmitJobDisabled(isJobExecuting(listResults));
    } catch (error) {
      console.error('Error in search:', error);
      openAlert('Error', error.message);
      return null;
    }
    return null;
  }

  function clearJobExecutions() {
    setBatchExecutions([]);
    setTotalCount(0);
    setBatchExecutionsPerPage([]);
  }

  const onSubmit = () => {
    fetchBatchExecutions(selectedJobDefinition, 0);
    history.push(`/batchExecutions?q=${selectedJobDefinition.jobDefinitionArn}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage || 0);
    setBatchExecutionsPerPage(batchExecutions.slice(newPage * rowsPerPage,
      (newPage + 1) * rowsPerPage));
  };

  async function viewReport(jobId, jobName) {
    history.push(`/batchExecutions/getReport/${jobName}/${jobId}`);
  }

  async function viewLogs(jobId, jobName) {
    history.push(`/batchExecutions/listLogs/${jobName}/${jobId}`);
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchJobDefinitions();
  }, []);

  return (
    <Box>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />

      <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>

        <Grid
          item
          container
          xs={12}
          spacing={0}
          direction="row"
          className={classes.bottomContent}
        >
          <Grid item xs={12} className={classes.topField}>
            <Autocomplete
              value={selectedJobDefinition}
              options={jobDefinitions}
              onChange={(event, value) => {
                clearTimeout(cancelPolling);
                setSelectedJobDefinition(value);
                setSearched(false);
                clearJobExecutions();
              }}
              getOptionLabel={(option) => option.jobDefinitionName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Definition"
                  variant="filled"
                />
              )}
              PopperComponent={CustomPopper}
              autoSelect
            />
          </Grid>

          <Grid
            container
            spacing={0}
            direction="row-reverse"
          >
            <Grid item xs={2} className={classes.bottomRightButton}>

              <Button type="submit" className={classes.primaryButton} color="secondary" variant="contained">
                SEARCH
              </Button>
            </Grid>
            {(selectedJobDefinition) && (searched) && (
            <Grid item xs={2} className={classes.gridSubmit}>
              <Grid
                container
                spacing={0}
                direction="row-reverse"
              >
                <Button
                  className={classes.button}
                  color="primary"
                  variant="text"
                  onClick={() => submitJobOnDemand()}
                  disabled={submitJobDisabled}
                >
                  <Grid container direction="column">
                    <Box className={classes.icon}><SubmitIcon /></Box>
                    <Typography variant="p" className={classes.text}>Launch job</Typography>
                  </Grid>
                </Button>
              </Grid>

            </Grid>
            )}
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
      >

        <Grid
          item
          container
          xs={12}
          spacing={0}
          direction="row"
          className={classes.bottomContent}
        >

          <TableContainer component={Paper}>
            <Table className={classes.historyTable}>
              <TableHead className={classes.headerBorderTable}>
                <TableRow className={classes.headerTable}>
                  <TableCell className={classes.headerCell}>Job ID</TableCell>
                  <TableCell className={classes.headerCell}>Job Name</TableCell>
                  <TableCell className={classes.headerCell}>Status</TableCell>
                  <TableCell className={classes.headerCell}>Created At</TableCell>
                  <TableCell className={classes.headerCell}>Finished At</TableCell>
                  <TableCell className={classes.headerCell}>More Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batchExecutionsPerPage.map((ex) => (
                  <TableRow>
                    <TableCell component="td" scope="release" style={{ width: '8%' }}>
                      {ex.jobId}
                    </TableCell>
                    <TableCell component="td" scope="release" style={{ width: '3%' }}>
                      { ex.jobName }
                    </TableCell>
                    <TableCell style={{ width: '5%' }}>
                      { ex.status}
                    </TableCell>
                    <TableCell style={{ width: '15%' }}>
                      { moment(new Date(ex.createdAt)).format('DD/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell style={{ width: '15%' }}>
                      {ex.stoppedAt ? moment(new Date(ex.stoppedAt)).format('DD/MM/yyyy HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell style={{ width: '17%' }}>
                      {/* <Grid container>
                      <Grid item xs={6}> */}
                      <Tooltip title="View report" placement="start-top">
                        <IconButton
                          aria-label="view"
                          onClick={() => viewReport(ex.jobId, ex.jobName)}
                          disabled={ex.status !== 'SUCCEEDED' || !ex.hasReport}
                        >
                          <ViewIcon />
                        </IconButton>
                      </Tooltip>
                      {/* </Grid>
                        <Grid item xs={6}> */}
                      <Tooltip title="View logs" placement="start-top">
                        <IconButton
                          aria-label="logs"
                          onClick={() => viewLogs(ex.jobId, ex.jobName)}
                          disabled={!STATUSES_WITH_LOGS.includes(ex.status)}
                        >
                          <ListAltIcon />
                        </IconButton>
                      </Tooltip>
                      {/* </Grid>
                      </Grid> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow key="paginationFooter">
                  <TablePagination
                    rowsPerPageOptions={[20]}
                    colSpan={10}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
    </Box>

  );
}
