/* eslint-disable import/no-named-default */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router, useLocation, Link,
} from 'react-router-dom';
import { Hidden, Typography, withStyles } from '@material-ui/core';

const drawerWidth = 300;

const leftBarList = [
  {
    name: 'Batch Executions', path: '/batchExecutions', icon: <ArrowRightIcon />,
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar + 1,
    height: '100%',
    '& .MuiDrawer-paperAnchorLeft': {
      left: 'auto',
      boxShadow: theme.customShadows.rightShadow,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  logo: {
    height: 77,
    width: 132,
    marginLeft: 90,
    marginRight: 25,
    marginTop: 35,
    marginBottom: 35,
  },
  drawerItem: {
    minHeight: 48,
    width: 280,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 6,
    paddingBottom: 6,
    marginLeft: 12,
    marginRight: 12,
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
  },
  drawerItemIcon: {
    paddingLeft: 12,
    minWidth: 24,
  },
  drawerItemText: {
    '& .MuiTypography-root': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    paddingLeft: 24,
  },
}));

function LeftBarItem({
  name, path, icon, closeDrawer,
}) {
  const { pathname } = useLocation();
  const isThisLocation = (localPath) => pathname.substring(0, localPath.length) === localPath;

  const classes = useStyles();

  return (
    <ListItem
      button
      component={Link}
      to={path}
      key={name}
      onClick={closeDrawer}
      selected={isThisLocation(path)}
      className={classes.drawerItem}
    >
      <ListItemIcon className={classes.drawerItemIcon}>
        {icon || ''}
      </ListItemIcon>
      <ListItemText primary={name} className={classes.drawerItemText} />
    </ListItem>
  );
}
LeftBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default function LeftBar({ open, setOpen }) {
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <nav className={classes.nav}>
      <Hidden mdUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawer}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <div className={classes.drawerContainer}>
            <List>
              {leftBarList.map((item) => (
                <LeftBarItem
                  name={item.name}
                  path={item.path}
                  icon={item.icon}
                  permission={item.permission}
                  closeDrawer={closeDrawer}
                />
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          elevation={5}
        >
          <div className={classes.drawerContainer}>
            <List>
              {leftBarList.map((item) => (
                <LeftBarItem
                  name={item.name}
                  path={item.path}
                  icon={item.icon}
                  permission={item.permission}
                  closeDrawer={closeDrawer}
                />
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>
    </nav>
  );
}
LeftBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
